import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener("DOMContentLoaded", function(event){
    window.addEventListener("load", function(e){

    gsap.registerPlugin(ScrollTrigger);

    if ($(window).width() > 1200) {

        //index-hero
        const heroSection = document.querySelector('.index-hero');
        if(heroSection != null) {
            const heroSectionTitle = heroSection.querySelectorAll('.index-hero h1 span');
            const heroDescription = heroSection.querySelector('.index-hero__description_text');
            const heroButton = heroSection.querySelector('.index-hero__button');

            gsap.set(heroSectionTitle, {y: '30px', visibility: "hidden", opacity: 0,})
            gsap.set(heroDescription, {y: '30px', visibility: "hidden", opacity: 0,})
            gsap.set(heroButton, {y: '30px', visibility: "hidden", opacity: 0,})

            const heroTl = gsap.timeline({
                scrollTrigger: {
                    trigger: heroSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            heroTl.to(heroSectionTitle, {y: 0, visibility: "visible", opacity: 1,}, 0)
            heroTl.to(heroDescription, {y: 0, visibility: "visible", opacity: 1,}, 0.5)
            heroTl.to(heroButton, {y: 0, visibility: "visible", opacity: 1,}, 0.5)
        }

        //index-advantages
        const advantagesSection = document.querySelector('.index-advantages');
        if(advantagesSection != null) {

            const advantagesList = document.querySelector('.index-advantages__list');
            const advantagesNumber = advantagesList.querySelectorAll('span');
            const advantagesDescription = advantagesList.querySelectorAll('p');

            gsap.set(advantagesNumber, {y: '-200px', visibility: "hidden", opacity: 0,})
            gsap.set(advantagesDescription, {y: '200px', visibility: "hidden", opacity: 0,})

            const advantagesTl = gsap.timeline({
                scrollTrigger: {
                    trigger: advantagesSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            advantagesTl.to(advantagesNumber, {y: 0, visibility: "visible", opacity: 1,}, 1)
            advantagesTl.to(advantagesDescription, {y: 0, visibility: "visible", opacity: 1,},1)
        }

        //index-means
        const indexMeansSection = document.querySelector('.index-means');
        if(indexMeansSection != null) {

            const indexMeansSlideRight = indexMeansSection.querySelectorAll('.index-means .slide-in-right');
            const indexMeansSlideLeft = indexMeansSection.querySelectorAll('.index-means .slide-in-left');


            gsap.set(indexMeansSlideRight, {x: '100vw', visibility: "hidden", opacity: 0,})
            gsap.set(indexMeansSlideLeft, {x: '-100vw', visibility: "hidden", opacity: 0,})

            const indexMeansTl = gsap.timeline({
                scrollTrigger: {
                    trigger: indexMeansSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            indexMeansTl.to(indexMeansSlideRight, {x: 0, visibility: "visible", opacity: 1, duration: 1}, 1)
            indexMeansTl.to(indexMeansSlideLeft, {x: 0, visibility: "visible", opacity: 1, duration: 1}, 1)

        }

        //form
        const formSection = document.querySelector('.form-section');
        if(formSection != null) {

            const formTitle = document.querySelector('.form-section__title');
            const formContent = document.querySelector('.form-section__form');

            gsap.set(formTitle, {y: '200px', visibility: "hidden", opacity: 0,})
            gsap.set(formContent, {y: '200px', visibility: "hidden", opacity: 0,})

            const formTl = gsap.timeline({
                scrollTrigger: {
                    trigger: formSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            formTl.to(formTitle, {y: 0, visibility: "visible", opacity: 1,}, 0.5)
            formTl.to(formContent, {y: 0, visibility: "visible", opacity: 1,}, 0.75)
        }

        //section-heading
        const headingSection = document.querySelector('.section-heading');
        if(headingSection != null) {

            const headingTitle = document.querySelector('.section-heading__title');
            const headingDescription = document.querySelector('.section-heading__description');

            gsap.set(headingTitle, {y: '-200px', visibility: "hidden", opacity: 0,})
            gsap.set(headingDescription, {y: '200px', visibility: "hidden", opacity: 0,})

            const headingTl = gsap.timeline({
                scrollTrigger: {
                    trigger: headingSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            headingTl.to(headingTitle, {y: 0, visibility: "visible", opacity: 1,}, 0)
            headingTl.to(headingDescription, {y: 0, visibility: "visible", opacity: 1,}, 0)
        }


        //solutions
        const solutionsSection = document.querySelector('.solutions');
        if(solutionsSection != null) {

            const solutionsTitle = document.querySelector('.solutions__title');
            const solutionsAccordion = solutionsSection.querySelectorAll('.solutions .accordion');

            gsap.set(solutionsTitle, {y: '-200px', visibility: "hidden", opacity: 0,})
            gsap.set(solutionsAccordion, {x: '100px', visibility: "hidden", opacity: 0,})

            const solutionsTl = gsap.timeline({
                scrollTrigger: {
                    trigger: solutionsSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            solutionsTl.to(solutionsTitle, {y: 0, visibility: "visible", opacity: 1,}, 0)
            solutionsTl.to(solutionsAccordion, {
                visibility: "visible",
                opacity: 1,
                duration: .25,
                delay: .25,
                x: 0,
                stagger: {
                    amount: 1
                }
            },0.5)
        }

        //about-hero
        const aboutHeroSection = document.querySelector('.about-hero');
        if(aboutHeroSection != null) {

            const aboutHeroTitle = document.querySelector('.about-hero__title');
            const aboutHeroDescription = document.querySelector('.about-hero__description');

            gsap.set(aboutHeroTitle, {y: '-200px', visibility: "hidden", opacity: 0,})
            gsap.set(aboutHeroDescription, {y: '200px', visibility: "hidden", opacity: 0,})

            const aboutHeroTl = gsap.timeline({
                scrollTrigger: {
                    trigger: solutionsSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            aboutHeroTl.to(aboutHeroTitle, {y: 0, visibility: "visible", opacity: 1,}, 0)
            aboutHeroTl.to(aboutHeroDescription, {y: 0, visibility: "visible", opacity: 1,}, 0)
        }

        //about-advantages
        const aboutAdvantagesSection = document.querySelector('.about-advantages');
        if(aboutAdvantagesSection != null) {

            const aboutAdvantagesTitle = document.querySelector('.about-advantages__title');
            const aboutAdvantagesSubtitle = document.querySelector('.about-advantages__subtitle');
            const aboutAdvantagesDescription = document.querySelector('.about-advantages__description');
            const aboutAdvantagesList = document.querySelector('.about-advantages__list');

            gsap.set(aboutAdvantagesTitle, {y: '200px', visibility: "hidden", opacity: 0,})
            gsap.set(aboutAdvantagesSubtitle, {y: '200px', visibility: "hidden", opacity: 0,})
            gsap.set(aboutAdvantagesDescription, {y: '200px', visibility: "hidden", opacity: 0,})
            gsap.set(aboutAdvantagesList, {y: '200px', visibility: "hidden", opacity: 0,})

            const aboutAdvantagesTl = gsap.timeline({
                scrollTrigger: {
                    trigger: aboutAdvantagesSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            aboutAdvantagesTl.to(aboutAdvantagesSubtitle, {y: 0, visibility: "visible", opacity: 1,}, 0)
            aboutAdvantagesTl.to(aboutAdvantagesTitle, {y: 0, visibility: "visible", opacity: 1,}, 0.25)
            aboutAdvantagesTl.to(aboutAdvantagesDescription, {y: 0, visibility: "visible", opacity: 1,}, 0.5)
            aboutAdvantagesTl.to(aboutAdvantagesList, {y: 0, visibility: "visible", opacity: 1,}, 0.75)
        }

        //about-business
        const aboutBusinessSection = document.querySelector('.about-business');
        if(aboutBusinessSection != null) {

            const aboutBusinessTitle = document.querySelector('.about-business__title');
            const aboutBusinessSubtitle = document.querySelector('.about-business__subtitle');
            const aboutBusinessSlide = aboutBusinessSection.querySelectorAll('.about-business__card');

            gsap.set(aboutBusinessTitle, {y: '200px', visibility: "hidden", opacity: 0,})
            gsap.set(aboutBusinessSubtitle, {y: '200px', visibility: "hidden", opacity: 0,})
            gsap.set(aboutBusinessSlide, {y: '30px', visibility: "hidden", opacity: 0,})


            const aboutBusinessTl = gsap.timeline({
                scrollTrigger: {
                    trigger: aboutBusinessSection,
                    start: 'top bottom',
                    end: 'bottom top',
                    invalidateOnRefresh: true,
                }
            })

            aboutBusinessTl.to(aboutBusinessSubtitle, {y: 0, visibility: "visible", opacity: 1,}, 0)
            aboutBusinessTl.to(aboutBusinessTitle, {y: 0, visibility: "visible", opacity: 1,}, 0.25)
            aboutBusinessTl.to(aboutBusinessSlide, {
                visibility: "visible",
                opacity: 1,
                duration: .2,
                delay: .2,
                y: 0,
                stagger: {
                    amount: 1
                }
            },0.5)
        }
    }

    }, false);
});